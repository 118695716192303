<template>
  <div data-app class="container" style="width: 90%">
    <v-form
      v-model="valid"
      ref="form"
      v-if="showForm1"
      lazy-validation
      id="accountType"
      style="margin-top: 50px"
    >
      <div>
        <div class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Choose Account Type
        </div>
        <span>Before Creating account, please first choose your account type.</span>
        <div class="form-group mb-form">
          <v-radio-group
            :rules="[(v) => !!v || 'Item is required']"
            required
            v-model.trim="$v.type.$model"
          >
            <v-radio
              style="background: #f3f7fa; margin-bottom: 16px"
              label="Caregiver"
              value="Caregiver"
            >
              <template slot="label">
                <strong class="m-3 pt-3">Caregiver</strong>
              </template></v-radio
            >
            <v-radio
              style="background: #f3f7fa; margin-bottom: 16px"
              label="Client/Consumer"
              value="Client/Consumer"
            >
              <template slot="label">
                <strong class="m-3 pt-3">Client/Consumer</strong>
              </template></v-radio
            >
          </v-radio-group>
        </div>

        <div class="text-center">
          <v-btn
            rounded
            class="btn btn-success mr-3 mb-5"
            style="width: 100%; color: #ffff;height:60px"
            @click="next()"
          >
            Next
          </v-btn>
        </div>
        <span
          ><a role="button" tabindex="0" @click="AlreadyAccount()"
            >Already have Account ? Sign in.</a
          ></span
        >
      </div>
    </v-form>
    <v-form
      v-model="valid"
      ref="form1"
      v-if="showForm2 && type == 'Client/Consumer'"
      lazy-validation
      id="accountType"
      style="margin-top: 50px"
    >
      <div class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        <span>Create Client/Consumer Account.</span>
        <div class="form-group">
          <v-row>
            <div
              class="col-md-12"
              :class="{ invalid: $v.client.firstName.$error }"
            >
              <v-text-field
                v-model.trim="$v.client.firstName.$model"
                maxlength="50"
              >
                <template #label> First Name </template>
              </v-text-field>

              <div class="invalid-feedback" v-if="$v.client.firstName.$error">
                <span v-if="$v.client.firstName.$error"
                  >First Name is required</span
                >
              </div>
            </div>
            <div
              class="col-md-12"
              :class="{ invalid: $v.client.lastName.$error }"
            >
              <v-text-field
                v-model.trim="$v.client.lastName.$model"
                label="Last Name *"
                maxlength="50"
              >
                <template #label> Last Name </template></v-text-field
              >

              <div class="invalid-feedback" v-if="$v.client.lastName.$error">
                <span v-if="$v.client.lastName.$error"
                  >Last Name is required</span
                >
              </div>
            </div>
            <div class="col-md-12" :class="{ invalid: $v.client.uci.$error }">
              <v-text-field
                v-model.trim="$v.client.uci.$model"
                type="number"
                :max="7"
                maxlength="7"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              >
                <template #label> Client/Consumer UCI </template></v-text-field
              >

              <div class="invalid-feedback" v-if="$v.client.uci.$error">
                <span v-if="!$v.client.uci.maxLength"
                  >Uci should be less than 7</span
                >
                <span v-else-if="$v.client.uci.$error">uci is required</span>
              </div>
            </div>
            <div class="col-md-12" :class="{ invalid: $v.client.dob.$error }">
              <label>DOB</label>
              <date-picker
                ref="dp1"
                @focusin.native="onfocusin"
                placeholder="Choose Birth Date"
                :config="datePickerOptions"
                v-model.trim="$v.client.dob.$model"
              ></date-picker>

              <div class="invalid-feedback" v-if="$v.client.dob.$error">
                <span v-if="$v.client.dob.$error">Dob is required</span>
              </div>
            </div>
            <div class="col-md-12" :class="{ invalid: $v.client.email.$error }">
              <v-text-field
                v-model.trim="$v.client.email.$model"
                maxlength="50"
              >
                <template #label> Email </template>
              </v-text-field>
              <div class="invalid-feedback" v-if="$v.client.email.$error">
                <span v-if="$v.client.email.$error">Email is In_valid</span>
              </div>
            </div>

            <div
              class="col-md-12"
              :class="{ invalid: $v.client.password.$error }"
            >
              <v-text-field
                v-model="client.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                @click:append="show1 = !show1"
              ></v-text-field>
              <div class="invalid-feedback" v-if="$v.client.password.$error">
                <span v-if="$v.client.password.$error"
                  >password is required</span
                >
              </div>
            </div>

            <div
              class="col-md-12"
              :class="{ invalid: $v.client.confirmpassword.$error }"
            >
              <v-text-field
                v-model="client.confirmpassword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Confirm Password"
                @click:append="show1 = !show1"
              ></v-text-field>
              <div
                class="invalid-feedback"
                v-if="$v.client.confirmpassword.$error"
              >
                <span v-if="!$v.client.confirmpassword.required"
                  >confirm password is required</span
                >
                <span v-else-if="!$v.client.confirmpassword.sameAsPassword"
                  >Password and Confirm Password should match</span
                >
              </div>
            </div>
          </v-row>
        </div>

        <div class="text-center">
          <v-btn
            rounded
            class="btn btn-success mr-3 mb-5"
            style="width: 100%; color: #ffff;height:60px"
            @click="create()"
          >
            Create Account
          </v-btn>
        </div>
      </div>
      <span
        ><a role="button" tabindex="0" @click="back()"
          >Back to Choose Account Type</a
        ></span
      >
    </v-form>
    <v-form
      v-model="valid"
      ref="form1"
      v-if="showForm2 && type == 'Caregiver'"
      lazy-validation
      id="accountType"
      style="margin-top: 50px"
    >
      <div class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        <span>Create Caregiver Account.</span>
        <div class="form-group mb-form">
          <v-row>
            <div
              class="col-md-12 mb-form"
              :class="{ invalid: $v.careGiver.empID.$error }"
            >
              <v-text-field
                v-model.trim="$v.careGiver.empID.$model"
                type="number"
                maxlength="5"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                label="employeeId"
              >
                <template #label> Employee ID </template></v-text-field
              >

              <div class="invalid-feedback" v-if="$v.careGiver.empID.$error">
                <span v-if="$v.careGiver.empID.$error"
                  >Employee ID is required</span
                >
              </div>
            </div>
            <div
              class="col-md-12"
              :class="{ invalid: $v.careGiver.ssn.$error }"
            >
              <v-text-field
                v-model.trim="$v.careGiver.ssn.$model"
                label="Last 4 Digits of SSN"
                type="number"
                :max="4"
                maxlength="4"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              >
                <template #label> Last 4 Digits of SSN </template></v-text-field
              >

              <div class="invalid-feedback" v-if="$v.careGiver.ssn.$error">
                <span v-if="!$v.careGiver.ssn.maxLength"
                  >SSN should be less than 9</span
                >
                <span v-else-if="$v.careGiver.ssn.$error">SSN is required</span>
              </div>
            </div>
            <div
              class="col-md-12 mb-form"
              :class="{ invalid: $v.careGiver.dob.$error }"
            >
              <label>DOB</label>
              <date-picker
                ref="dp1"
                @focusin.native="onfocusin"
                placeholder="Choose Birth Date"
                :config="datePickerOptions"
                v-model.trim="$v.careGiver.dob.$model"
              ></date-picker>

              <div class="invalid-feedback" v-if="$v.careGiver.dob.$error">
                <span v-if="$v.careGiver.dob.$error">Dob is required</span>
              </div>
            </div>

            <div
              class="col-md-12 mb-form"
              :class="{ invalid: $v.careGiver.language.$error }"
            >
              <label> Language </label>
              <b-form-select
                class="form-control"
                v-model.trim="$v.careGiver.language.$model"
                :rules="['Required']"
                :options="language"
              ></b-form-select>
              <div class="invalid-feedback" v-if="$v.careGiver.language.$error">
                <span v-if="$v.careGiver.language.$error"
                  >language is required</span
                >
              </div>
            </div>

            <div
              class="col-md-12"
              :class="{ invalid: $v.careGiver.email.$error }"
            >
              <v-text-field
                v-model.trim="$v.careGiver.email.$model"
                required
                maxlength="50"
              >
                <template #label> Email </template>
              </v-text-field>
              <div class="invalid-feedback" v-if="$v.careGiver.email.$error">
                <span v-if="$v.careGiver.email.$error">Email is In_valid</span>
              </div>
            </div>

            <div
              class="col-md-12 mb-form"
              :class="{ invalid: $v.careGiver.password.$error }"
            >
              <v-text-field
                v-model="careGiver.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                @click:append="show1 = !show1"
              ></v-text-field>
              <div class="invalid-feedback" v-if="$v.careGiver.password.$error">
                <span v-if="$v.careGiver.password.$error"
                  >password is required</span
                >
              </div>
            </div>

            <div
              class="col-md-12"
              :class="{ invalid: $v.careGiver.confirmpassword.$error }"
            >
              <v-text-field
                v-model="careGiver.confirmpassword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Confirm Password"
                @click:append="show1 = !show1"
              ></v-text-field>
              <div
                class="invalid-feedback"
                v-if="$v.careGiver.confirmpassword.$error"
              >
                <span v-if="!$v.careGiver.confirmpassword.required"
                  >confirm password is required</span
                >
                <span v-else-if="!$v.careGiver.confirmpassword.sameAsPassword"
                  >Password and Confirm Password should match</span
                >
              </div>
            </div>
          </v-row>
        </div>

        <div class="text-center">
          <v-btn
            rounded
            class="btn btn-success mr-3 mb-5"
            style="width: 100%; color: #ffff;height:60px" 
            :disabled="createAccount"
            @click="create()"
          >
            Create Account
          </v-btn>
        </div>
      </div>
      <span
        ><a role="button" tabindex="0" @click="back()"
          >Back to Choose Account Type</a
        ></span
      >
    </v-form>
  </div>
</template> 
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, maxLength, sameAs } from "vuelidate/lib/validators";

var today = new Date();
export default {
  mixins: [validationMixin],
  validations: {
    careGiver: {
      empID: { required },
      dob: { required },
      password: { required },
      confirmpassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      ssn: { required, maxLength: maxLength(9) },
      language: { required },
    },
    client: {
      firstName: { required },
      lastName: { required },
      uci: { required },
      password: { required },
      confirmpassword: { required, sameAsPassword: sameAs("password") },
      dob: { required },
      email: { required, email },
    },
    type: {
      required,
    },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  data() {
    return {
      datePickerOptions: {
        format: "MM/DD/YYYY",
        maxDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        ),
        useCurrent: false,
      },
      careGiver: {
        empID: "",
        email: "",
        ssn: "",
        dob: "",
        language: "",
        confirmpassword: "",
        password: "",
      },
      client: {
        firstName: "",
        lastName: "",
        uci: "",
        confirmpassword: "",
        password: "",
        dob: "",
        email: "",
      },
      showForm1: true,
      valid: true,
      showForm2: false,
      type: "",
      createAccount: false,
      show1: false,
      language: [
        { value: "", text: "Please Select language" },
        { value: "English", text: "English" },
        { value: "Spanish", text: "Spanish" },
        { value: "Russian", text: "Russian" },
        { value: "Armenian", text: "Armenian" },
      ],
    };
  },
  mounted() {},
  computed: {},
  destroyed() {},
  methods: {
    onfocusin() {},
    next() {
      this.$v.type.$touch();
      if (this.$refs.form.validate()) {
        this.showForm2 = true;
        this.showForm1 = false;
      }
    },
    back() {
      this.showForm2 = false;
      this.showForm1 = true;
    },
    AlreadyAccount() {
      this.$router.push({ name: "login" });
    },
    create() {
      this.createAccount = true
      if (this.type == "Caregiver") {
        this.$v.careGiver.$touch();
        if (!this.$v.careGiver.$invalid) {
          this.careGiver.dob = new Date(this.careGiver.dob);

          this.$store
            .dispatch("signUpEmployee", this.careGiver)
            .then((response) => {
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "Account has been Created Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              } else {
                ;
                Swal.fire({
                  title: "",
                  text: response.validationMessage,
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                });
              }
            })
            .catch((ex) => {
              
            });
        }
      } else {
        this.$v.client.$touch();
      }
      this.createAccount = false
    },
  },
};
</script>  
<style>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
</style>